<template>
  <div :class="{ step: path === 'Basic' || path === 'Template', SpecialStep: path === 'Special' }">
    <h2>홀로그램 종류</h2>
    <div
      v-if="
        type !== '아크릴 쉐이커' &&
        type !== '자유형 책갈피' &&
        type !== '아크릴 램프' &&
        path !== 'Template' &&
        type !== '부착형 아크릴'
      "
      class="itemBox clearFix"
    >
      <ul class="itemBtns">
        <li
          class="itemBtn"
          :class="{ active: hologramNum === 0 }"
          @click="checkHologram(0, '쿨톤')"
          @keydown="checkHologram(0, '쿨톤')"
        >
          <div>
            <p>홀로그램[쿨톤]</p>
            <hr />
            <div />
            <img src="@/assets/goods/hologram_쿨톤.png" alt="" />
          </div>
        </li>
        <li
          class="itemBtn"
          :class="{ active: hologramNum === 1 }"
          @click="checkHologram(1, '웜톤')"
          @keydown="checkHologram(1, '웜톤')"
        >
          <div>
            <p>홀로그램[웜톤]</p>
            <hr />
            <img src="@/assets/goods/hologram_웜톤.png" alt="" />
          </div>
        </li>
        <li
          class="itemBtn"
          :class="{ active: hologramNum === 2 }"
          @click="checkHologram(2, '반짝이')"
          @keydown="checkHologram(2, '반짝이')"
        >
          <div>
            <p>반짝이</p>
            <hr />
            <img src="@/assets/goods/hologram_반짝이.png" alt="" />
          </div>
        </li>
        <li
          class="itemBtn"
          :class="{ active: hologramNum === 3 }"
          @click="checkHologram(3, '모자이크')"
          @keydown="checkHologram(3, '모자이크')"
        >
          <div>
            <p>모자이크</p>
            <hr />
            <img src="@/assets/goods/hologram_모자이크.png" alt="" />
          </div>
        </li>
        <li
          class="itemBtn"
          :class="{ active: hologramNum === 4 }"
          @click="checkHologram(4, '오로라')"
          @keydown="checkHologram(4, '오로라')"
        >
          <div>
            <p>오로라</p>
            <hr />
            <img src="@/assets/goods/hologram_오로라.png" alt="" />
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    path: {
      type: String,
    },
    hologramNum: {
      type: Number,
    },
  },
  computed: {
    ...mapGetters({
      type: 'Goods/type',
    }),
  },
  methods: {
    async checkHologram(i, hologram, margin) {
      await this.$emit('checkHologram', 'hologram_kind', i, hologram)
    },
  },
}
</script>

<style scoped lang="scss">
// Basic
.step {
  width: 575px;
  margin: 0 auto 100px;

  > h2 {
    font-weight: 700;
    font-size: 26px;
    color: #848484;
    margin-bottom: 27px;
  }

  > .cardItemBox {
    font-weight: 400;

    > .itemBtns {
      float: left;
      margin-top: 8px;
      margin-bottom: 8px;

      > li {
        width: 280px;
        height: 240px;
        border: 2px solid #bcbcbc;
        border-radius: 10px;
        float: left;
        box-sizing: border-box;
        margin-right: 0px;
        cursor: pointer;

        &.active {
          border: 2px solid #ff6699;
          background-color: #fff7fa87;
        }

        > div {
          width: 234px;
          height: 100px;
          margin: 26px auto 0;

          > p {
            font-size: 16px;
            color: #707070;
            margin-bottom: 2px;
          }

          span {
            font-size: 10px;
            color: #707070;
            display: block;
          }

          > hr {
            width: 100%;
            border: 1px solid #e6e6e6;
            margin-top: 14px;
          }

          > img {
            margin-top: 25px;
            width: 100%;
            height: 100px;
            vertical-align: middle;
          }
        }
      }
    }
  }

  > .itemBox {
    font-weight: 400;

    > .itemBtns {
      float: left;
      margin-top: 8px;
      margin-bottom: 8px;

      > li {
        width: 180px;
        height: 240px;
        border: 2px solid #bcbcbc;
        border-radius: 10px;
        float: left;
        box-sizing: border-box;
        margin-right: 17px;
        cursor: pointer;
        margin-bottom: 15px;

        &:nth-child(3),
        &:last-child {
          margin-right: 0;
        }

        &.active {
          border: 2px solid #ff6699;
          background-color: #fff7fa87;
        }

        > div {
          width: 144px;
          height: 185px;
          margin: 26px auto 0;

          > p {
            font-size: 16px;
            color: #707070;
            margin-bottom: 2px;
          }

          > hr {
            width: 100%;
            border: 1px solid #e6e6e6;
            margin-top: 14px;
          }

          span {
            font-size: 10px;
            color: #707070;
            display: block;
          }

          > img {
            margin-top: 25px;
            margin-left: 15px;
            width: 120px;
            vertical-align: middle;
          }
        }
      }
    }
  }
  > .attachItemBox {
    font-weight: 400;

    > .itemBtns {
      float: left;
      margin-top: 8px;
      margin-bottom: 8px;

      > li {
        width: 280px;
        height: 240px;
        border: 2px solid #bcbcbc;
        border-radius: 10px;
        float: left;
        box-sizing: border-box;
        margin-right: 14px;
        cursor: pointer;

        &:last-child {
          margin-right: 0;
        }

        &.active {
          border: 2px solid #ff6699;
          background-color: #fff7fa87;
        }

        > div {
          width: 233px;
          height: 185px;
          margin: 26px auto 0;

          > p {
            font-size: 16px;
            color: #707070;
            margin-bottom: 2px;
          }

          > hr {
            width: 100%;
            border: 1px solid #e6e6e6;
            margin-top: 14px;
          }

          span {
            font-size: 10px;
            color: #707070;
            display: block;
          }

          > img {
            margin-top: 25px;
            width: 100%;
            height: 120px;
            vertical-align: middle;
          }
        }
      }
    }
  }
}

// Special
.SpecialStep {
  width: 380px;
  margin: 0 auto 55px;

  > h2 {
    font-weight: 700;
    font-size: 26px;
    color: #848484;
    margin-bottom: 13px;
  }

  > .itemBox {
    font-weight: 400;

    > div {
      margin-bottom: 5px;
    }

    > .itemBtns {
      float: left;

      > li {
        width: 118px;
        height: 160px;
        margin-right: 9px;
        border: 2px solid #bcbcbc;
        border-radius: 10px;
        float: left;
        box-sizing: border-box;
        cursor: pointer;
        margin-bottom: 10px;

        &:nth-child(3),
        &:last-child {
          margin-right: 0;
        }

        &.active {
          border: 2px solid #ff6699;
          background-color: #fff7fa87;
        }

        > div {
          width: 90px;
          height: auto;
          margin: 26px auto 0;

          > p {
            font-size: 14px;
            color: #707070;
            margin-bottom: 1px;
          }

          > span {
            display: block;
            font-size: 10px;
            color: #707070;
          }

          > hr {
            width: 100%;
            border: 1px solid #e6e6e6;
            margin-top: 5px;
          }

          > img {
            width: 80px;
            margin-left: 5px;
            margin-top: 10px;
          }
        }
      }
    }
  }
}

input[type='checkbox'] {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;

  + label {
    display: inline-block;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    &::before {
      content: ' ';
      display: inline-block;
      width: 16px;
      height: 16px;
      line-height: 15px;
      margin: -2px 8px 0 0;
      text-align: center;
      vertical-align: middle;
      background: #fafafa;
      border: 1px solid #979797;
      border-radius: 5px;
    }
  }

  &:checked {
    + label {
      &::before {
        content: '\2713';
        /* font-weight: 700; */
        color: #ff6699;
        text-shadow: 0.5px 0.5px #ff6699;
        background: #fedbe8;
        border-color: #979797;
      }
    }
  }
}

label {
  color: #707070;
  font-size: 14px;
}
</style>
